<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="70%">
            <div slot="title" class="dialog-header">
                <h3>Employee Management</h3>
                <i class="el-icon-close" @click="dialogVisible = false"></i>
            </div>
            <div class="dialog-body">
                <div class="division-one">
                    <div class="slideshow">
                        <el-carousel :interval="3000">
                            <el-carousel-item v-for="(item, index) in items" :key="index">
                                <img :src="item.src" alt="Slideshow Image">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="info">
                        <div class="sidemenu">
                            <p>Employee management software is a tool used by organization to manage various aspects of
                                their employees,including HR functions such as recruitment,onboarding,performance evalution,
                                training and development,scheduling,payroll,and benefits administration.It helps to
                                streamline
                                processes,improve communication and increase productivity while reducing administrative
                                tasks
                                and manual errors.Employee management software can be customized to fit the unique needs of
                                an
                                employee to ensure that the organization runs smoothly and effectively.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="division-two">
                    <h3>App Name</h3>
                    <el-input placeholder="Enter App name" v-model="input"></el-input><br><br>
                    <h3>Description</h3>
                    <el-input type="textarea" :rows="2" placeholder="Please input" v-model="textarea">
                    </el-input>
                    <el-row class="block-col-2">
                        <el-col :span="12"><br>
                            <span class="demonstration">CurrentRole</span>
                            <el-dropdown>
                                <span class="el-dropdown-link">
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item icon="el-icon-plus">HR</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-circle-plus">HrAdmin</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-circle-plus-outline">HrManager</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-check">Admin</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-circle-check">Trainee</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </el-col>
                        <el-col :span="12"><br>
                            <span class="demonstration">CurrentRole</span>
                            <el-dropdown>
                                <span class="el-dropdown-link">
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item icon="el-icon-plus">HR</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-circle-plus">HrAdmin</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-circle-plus-outline">HrManager</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-check">Admin</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-circle-check">Trainee</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </el-col>
                    </el-row><br>
                    <el-checkbox v-model="checked">Install the apllication on premise</el-checkbox><br><br>
                    <div class="buttons">
                        <el-button>Cancel</el-button>
                        <el-button type="primary">Install</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-button type="primary" @click="dialogVisible = true">Show Popup</el-button>
    </div>
</template>
<script>
export default {
    name: "employyeManagementPopUp",
    data() {
        return {
            dialogVisible: false,
            checked: false,
            input: "EmployeeManagement",
            textarea: 'Enter text here',
            items: [
                { src:require('@/assets/popUp/1.png')},
                { src: require('@/assets/popUp/2.png')},
                { src: require('@/assets/popUp/3.png')},
                { src: require('@/assets/popUp/4.png')},
            ],
        }
    }
};
</script>
<style scoped>
.slideshow {
    flex-basis: 100%;
    height: 100%;
}

.slideshow img {
    width: 100%;
    height: 95%;
    object-fit: cover;
}

.info {
    flex-basis: 50%;
    height: 30%;
}

.sidemenu {
    height: 108px;
    overflow-y: scroll;
    overflow-x: scroll;

    margin-right: 5px;
}

.sidemenu::-webkit-scrollbar {
    width: 0.7em;
}

.sidemenu::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    background: #e1e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #F5F5F5;
    background-color: #F5F5F5;

}

.dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialog-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.division-one {
    flex-basis: 100%;
    height: auto;
    overflow: auto;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
}

.division-two {
    width: 50%;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
}

.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
}

.el-icon-arrow-down {
    font-size: 10px;
}

.demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
}

.buttons {
    display: flex;
    justify-content: flex-end;
}
</style>